
import { defineComponent } from 'vue';
import moment from 'moment';

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-batch-report',
  components: {
    Datatable,
  },
  data() {
    return {
      moment: '' as any,
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        term_id: '',
        batch_id: '',
        total_terms: '',
        gender:'',
      },
      batchList: [],
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableData: [],
      tableHeader: [
        {
          name: 'Training Day',
          key: 'training_day',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Training Location',
          key: 'training_location',
          sortable: true,
        },
        {
          name: 'Start Time	',
          key: 'start_time',
          sortable: true,
        },
        {
          name: 'End Time',
          key: 'end_time',
          sortable: true,
        },
        {
          name: 'Lead Trainer',
          key: 'lead_trainer',
          sortable: true,
        },

        {
          name: 'Name',
          key: 'lead_trainer_name',
          sortable: true,
        },
        {
          name: '	Associate Trainer',
          key: 'associate_trainer',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'associate_trainer_Name',
          sortable: true,
        },
      ],
      api_url: '',
      instituteList: [],
      tranches_info: [],
      courseList: [],
      componentKey: 0,
      termList: [],
      detailsData: [],
      batchDetails: [],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async termInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_GET_BATCH_TERM_API +
          '?batch_info_id=' +
          this.batch.batch_id
      )
        .then((response) => {
          this.loading = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          "?entity_id=" +
           entity_id +
          "&institute_info_id=" +
          institute_info_id
        )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async showDetails() {
      if(this.batch.batch_id){
        this.loading = true;
        ApiService.get(
          'batch/report?tranche_id=' +
            this.batch.tranche_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&institute_id=' +
            this.batch.institute_id +
            '&course_id=' +
            this.batch.course_id +
            '&batch_id=' +
            this.batch.batch_id +
            '&term_id=' +
          this.batch.term_id +
          '&gender=' +
          this.batch.gender
        )
          .then((response) => {
            this.loading = false;
            this.detailsData = response.data.data;
            this.batchDetails = response.data.data.batch_details;
            this.showCourseNotice = true;
            this.componentKey += 1;
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
        }else{
        Swal.fire({
          title: 'Warning!',
          text: 'Please select all required field!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
       
      }
  
    },
  },
  setup() {},
});
